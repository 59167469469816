
















































































































































































































































import { Vue, Component } from "vue-property-decorator";
import Header from "../components/Header.vue";
import SideNav from "../components/SideNav.vue";
import ProfileWidget from "../components/ProfileWidget.vue";
import UserList from "./dashboard/UserList.vue";
import DoctorsActivity from "./dashboard/DoctorsActivity.vue";
import BuyAndSell from "./dashboard/BuyAndSell.vue";
import DonorsActivity from "./dashboard/DonorsActivity.vue";
import HospitalList from "./dashboard/HospitalList.vue";
import DocumentOverview from "./dashboard/DocumentOverview.vue";

@Component({
  components: {
    Header,
    SideNav,
    ProfileWidget,
    UserList,
    DoctorsActivity,
    BuyAndSell,
    DonorsActivity,
    HospitalList,
    DocumentOverview
  }
})
export default class AdminLayout extends Vue {
  public sidebarCollapse: boolean = true;

  sidebarToggleState(state: any) {
    this.sidebarCollapse = state;
    return false;
  }
}
